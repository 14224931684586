import React from 'react';
import { css, cx } from 'emotion';
import ReactTooltip from 'react-tooltip';
import questionMark from '../../assets/questionmark.svg';

const infoIconStyle = css`
  width: 1.25rem;
  height: 1.25rem;
`;
const tooltipHolder = css`
  display: inline;
`;
const tooltipStyle = css`
  display: flex !important;
  flex-direction: column;
  background-color: var(--white) !important;
  opacity: 1 !important;
  border: none !important;
  filter: drop-shadow(0px 8px 20px rgba(64, 64, 66, 0.12));
  border-radius: 8px !important;
  max-width: 21rem !important;
  margin: 0 !important;
  padding: 0.75rem 1rem !important;
  @media only screen and (max-width: 760px) {
    max-width: 60% !important;
  }
`;
const tooltipContentContainer = css`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  > p {
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--dark);
  }
`;
const tooltipButtonStyle = css`
  font-size: 1rem;
  line-height: 1.2em;
  font-weight: bold;
  color: var(--primaryBrand);
  background-color: unset;
  position: relative;
  border: none;
  align-self: flex-end;
  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 0.125rem;
    width: 100%;
    left: 0;
    right: 0;
    opacity: 1;
    background: var(--primaryBrand);
  }
`;

export const Tooltip = (props) => {
  const { children, id, customStyle } = props;
  return (
    <span className={cx(tooltipHolder, customStyle)}>
      <img
        data-tip
        data-for={id}
        src={questionMark}
        className={infoIconStyle}
        alt="info"
      />
      <ReactTooltip
        className={tooltipStyle}
        arrowColor="white"
        effect="solid"
        place="top"
        id={id}
      >
        <span className={tooltipContentContainer}>{children}</span>
        <button type="button" className={tooltipButtonStyle}>
          Got it
        </button>
      </ReactTooltip>
    </span>
  );
};
