import { makeAutoObservable } from 'mobx';
import { validationScheme } from '../validation/validationScheme';

export class StateStore {
  isSubmitting = false;

  apiError = null;

  stepper = [
    {
      name: 'Policy Disclaimer',
      validation: validationScheme.policyDisclaimer,
    },
    {
      name: 'Business Type',
      validation: validationScheme.businessType,
    },
  ];

  isPubOrWine = false;

  isShop = false;

  guestrooms = false;

  fullRangeFrying = false;

  moreThanFourEmployees = false;

  constructor() {
    makeAutoObservable(this);
  }
  setValues({
    isSubmitting,
    apiError,
    stepper,
    isPubOrWine,
    isShop,
    guestrooms,
    fullRangeFrying,
    moreThanFourEmployees,
  }) {
    this.isSubmitting = isSubmitting;
    this.apiError = apiError;
    this.stepper = stepper;
    this.isPubOrWine = isPubOrWine;
    this.isShop = isShop;
    this.guestrooms = guestrooms;
    this.fullRangeFrying = fullRangeFrying;
    this.moreThanFourEmployees = moreThanFourEmployees;
  }

  getStepper = () => {
    return this.stepper;
  };
  setStepper = (stepper) => {
    this.stepper = stepper;
  };
  getIsPubOrWine = () => {
    return this.isPubOrWine;
  };
  setIsPubOrWine = (isPubOrWine) => {
    this.isPubOrWine = isPubOrWine;
  };
  getIsShop = () => {
    return this.isShop;
  };
  setIsShop = (isShop) => {
    this.isShop = isShop;
  };
  getGuestrooms = () => {
    return this.guestrooms;
  };
  setGuestrooms = (guestrooms) => {
    this.guestrooms = guestrooms;
  };
  getFullRangeFrying = () => {
    return this.fullRangeFrying;
  };
  setFullRangeFrying = (fullRangeFrying) => {
    this.fullRangeFrying = fullRangeFrying;
  };
  getMoreThanFourEmployees = () => {
    return this.moreThanFourEmployees;
  };
  setMoreThanFourEmployees = (moreThanFourEmployees) => {
    this.moreThanFourEmployees = moreThanFourEmployees;
  };
}

export const stateStore = new StateStore();
