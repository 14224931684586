import { css, cx } from 'emotion';
import React, { useCallback, useEffect } from 'react';
import closeIcon from '../../assets/closeIcon.svg';

const modalStyle = css`
  position: fixed; /* Stay in place */
  z-index: 7; /* Sit on top */
  left: 0;
  top: 0;
  overflow: auto;
  width: 100%; /* Full width */
  height: 100%;
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  -webkit-animation-name: fadeIn; /* Fade in the background */
  -webkit-animation-duration: 0.4s;
  animation-name: fadeIn;
  animation-duration: 0.4s;
`;

const removeDefaultButtonStyle = css`
  background: transparent;
  border: none;
`;

const modalContentStyle = css`
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: var(--white);
  border-top: 2px solid var(--primaryBrand);
  left: 50%;
  transform: translate(-50%, 0);
  width: 50%;
  max-width: 50%;
  max-height: calc(100vh - 7.375rem);
  height: fit-content;
  overflow: auto;
  top: 7.375rem;
  bottom: 0;
  border-radius: 12px;
  padding: 1.5rem 1rem;
  @media (max-width: 768px) {
    bottom: 0;
    left: 0;
    top: 0;
    transform: unset;
    height: 100%;
    max-height: 100vh;
    max-width: unset;
    width: 100%;
    border-radius: 0;
  }
`;

const modalHeaderStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  border-radius: 12px 12px 0 0;

  > h4 {
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.2em;
    letter-spacing: -0.03em;
    color: var(--dark);
  }
`;

export const Modal = (props) => {
  const { children, handleClose, customModalStyle, title } = props;
  const escFunction = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    window.onclick = (event) => {
      const modal = document.getElementById('myModal');
      if (event.target === modal) {
        handleClose();
      }
    };
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction, handleClose]);
  return (
    <div id="myModal" className={modalStyle}>
      <div className={cx(modalContentStyle, customModalStyle)}>
        <div className={modalHeaderStyle}>
          <h4>{title}</h4>
          <button
            className={removeDefaultButtonStyle}
            onClick={handleClose}
            type="button"
          >
            <img src={closeIcon} alt="close" />
          </button>
        </div>
        <>{children}</>
      </div>
    </div>
  );
};
