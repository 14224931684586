import { css, cx } from 'emotion';
import * as React from 'react';
import errorIconUrl from '../../assets/errorIcon.svg';

const errorStyle = css`
  margin-top: auto;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
`;
const errorIconStyle = css`
  width: 1.25rem;
  height: 1.25rem;
`;

const errorTextStyle = css`
  padding-left: 0.375rem;
  font-size: 0.875rem;
  line-height: 1.2em;
  color: var(--error);
`;

const sfTextStyle = css`
  color: #ffffff;
  font-size: 0.75rem;
  font-family: Lato, sans-serif;
  font-weight: 600;
  line-height: 1.8;
`;
const sfContainerStyle = css`
  justify-content: center;
  margin: 0;
`;

export const ErrorMessage = (props) => {
  const { errorMessage, showIcon = true, customStyle, salesForce } = props;
  return (
    <div
      className={cx(errorStyle, customStyle, salesForce && sfContainerStyle)}
    >
      {showIcon && !salesForce && (
        <img src={errorIconUrl} className={errorIconStyle} alt="error" />
      )}
      <p className={salesForce ? sfTextStyle : errorTextStyle}>
        {(salesForce && 'This field is required.') ||
          errorMessage?.code ||
          errorMessage?.description ||
          errorMessage ||
          'This question is required'}
      </p>
    </div>
  );
};
