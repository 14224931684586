import React from 'react';
import { css, cx } from 'emotion';

const titleStyle = css`
  color: var(--dark);
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.25em;
  letter-spacing: -0.03em;
  margin-bottom: 1.5rem;
`;

export const PageTitle = (props) => {
  const { title, customStyle } = props;
  return <h1 className={cx(titleStyle, customStyle)}>{title}</h1>;
};
