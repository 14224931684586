import get from 'lodash/get';
import { observable } from 'mobx';

export class StateStore {
  salesforceLeadId = undefined;
  salesforceOpportunityId = undefined;
  salesforceContactId = undefined;
  firstName = '';
  lastName = '';
  email = '';
  phone = '';
  quoteRef = '';

  @observable
  created = false;

  @observable
  rateLoader = false;

  @observable
  isSalesforceWorking = true;

  saveSalesforceData = (quote) => {
    this.firstName = get(quote, 'firstName');
    this.lastName = get(quote, 'lastName');
    this.email = get(quote, 'email');
    this.phone = get(quote, 'phone');
    if (quote.salesforceOpportunityId) {
      this.salesforceOpportunityId = quote.salesforceOpportunityId;
    }
    if (quote.salesforceContactId) {
      this.salesforceContactId = quote.salesforceContactId;
    }
    this.quoteRef = quote.quoteRef;
    this.salesforceLeadId = quote.leadId;
    this.salesforceContactId = quote.contactId;
    this.salesforceOpportunityId = quote.opportunityId;
    this.setCreated(true);
  };

  saveDataToFormik = (formik) => {
    const { setValues, values } = formik;
    const updateData = {
      FirstName: this.firstName || values.FirstName,
      LastName: this.lastName || values.LastName,
      Email: this.email || values.Email,
      Phone: this.phone || values.Phone,
    };

    setValues({
      ...values,
      ...updateData,
    });
  };
  getCreated = () => {
    return this.created;
  };
  setCreated = (value) => {
    this.created = value;
  };
  getIsSalesforceWorking = () => {
    return this.isSalesforceWorking;
  };
  setIsSalesforceWorking = (value) => {
    this.isSalesforceWorking = value;
  };
  getRateLoader = () => {
    return this.rateLoader;
  };
  setRateLoader = (value) => {
    this.rateLoader = value;
  };
}

export const salesforceStore = new StateStore();
