import React from 'react';
import { observer } from 'mobx-react';
import { Formik } from 'formik';
import { css } from '@emotion/css';
import * as yup from 'yup';
import { InputField } from '../components/InputField';
import { ButtonContainer } from '../components/ButtonContainer';
import { Loader } from '../components/Loader';
import { PageTitle } from '../components/PageTitle';
import { Button } from '../components/Button';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import { EmptySidebar } from './EmptySidebar';
import { useCreateLead } from '../../api/api';

const bodyStyle = css`
  min-height: calc(100vh - 10px);
  display: grid;
  grid-template-columns: Max(25vw, 14.5rem) 1fr Max(25vw, 14.5rem);
  grid-template-areas:
    'header header header'
    'aside main info'
    'footer footer footer';
  background-color: var(--white);
  @media (max-width: 768px) {
    grid-template-areas:
      'header'
      'aside'
      'main'
      'info'
      'footer';
    grid-template-columns: 100%;
    grid-template-rows: auto;
  }
`;

const contentContainer = css`
  grid-area: main;
  display: flex;
  position: relative;
  flex-direction: column;
  @media (max-width: 768px) {
    min-height: calc(100vh - 6rem);
  }
  padding: 1rem;
`;

const formContainerStyle = css`
  background-color: var(--primaryBrand);
  border-radius: 15px;
  padding: 1.5rem;
  margin: 1.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto 0;

  @media (max-width: 768px) {
    margin: auto 0.5rem;
  }
`;

const titleStyle = css`
  color: #ffffff;
  font-size: 2.25rem;
  margin-bottom: 1.15rem;
  text-align: center;
`;

const infoStyle = css`
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.8;
  text-align: center;
  margin: 0 1.5rem 2rem;
`;

const nameContainerStyle = css`
  display: flex;
  width: 100%;
`;

const spaceBetweenStyle = css`
  width: 10%;
`;

const bottomContainerStyle = css`
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
  > button {
    margin-left: auto;
  }
`;

const salesforceValidationScheme = yup.object().shape({
  firstName: yup.string().required('First name is required field.'),
  lastName: yup.string().required('Last name is required field.'),
  phone: yup.string().required('Phone is required field.'),
  email: yup
    .string()
    .email('Email must be a valid email.')
    .required('Email is required field.'),
});

const salesforceInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
};

const SalesforceDetails = observer(() => {
  const { mutate: createLead, isLoading: createLeadLoading } = useCreateLead();
  if (createLeadLoading) {
    return <Loader message="Loading your quote..." />;
  }

  return (
    <div className={bodyStyle}>
      <Header />
      <main className={contentContainer}>
        <Formik
          initialValues={salesforceInitialValues}
          validationSchema={salesforceValidationScheme}
          onSubmit={(values) => {
            createLead(values);
          }}
        >
          {(props) => (
            <div className={formContainerStyle}>
              <PageTitle title="Start Your Quote" customStyle={titleStyle} />
              <p className={infoStyle}>
                Let us have a few details so we can get started with your quote…
              </p>
              <div className={nameContainerStyle}>
                <InputField
                  name="firstName"
                  id="firstName"
                  placeholder="First Name"
                  required
                  salesForce
                  fullwidth
                />
                <div className={spaceBetweenStyle} />
                <InputField
                  name="lastName"
                  id="lastName"
                  placeholder="Last Name"
                  required
                  salesForce
                  fullwidth
                />
              </div>
              <InputField
                name="email"
                id="email"
                placeholder="Email"
                type="email"
                required
                salesForce
                fullwidth
              />
              <InputField
                name="phone"
                id="phone"
                placeholder="Phone"
                type="number"
                required
                salesForce
                fullwidth
              />
              <ButtonContainer customAlignment={bottomContainerStyle}>
                <Button
                  type="submit"
                  label="Start Quote"
                  handleClick={props.handleSubmit}
                  salesForce
                />
              </ButtonContainer>
            </div>
          )}
        </Formik>
      </main>
      <EmptySidebar />
      <Footer />
    </div>
  );
});

export default SalesforceDetails;
