import { useQuery, useMutation, useQueryClient } from 'react-query';
import * as Sentry from '@sentry/browser';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import get from 'lodash/get';
import { useFirebase } from '../components/Firebase';
import {
  OCCUPATION_FAMILY_CODE_PUB,
  OCCUPATION_FAMILY_CODE_REST,
  SCHEME_CODE,
} from '../constants/constants';
import { quoteStore } from '../store/quote-store';
import { stateStore } from '../store/state-store';
import { getUtmFields } from '../utils/get-utm-fields';
import { salesforceStore } from '../store/salesforce-store';

const getExemptReason = (values) => {
  if (
    values.address?.town &&
    !['Guernsey', 'Jersey'].includes(values.address?.town)
  ) {
    return 'Business is registered in Jersey or Guernsey';
  }
  if (get(values, '2313Employees') === 0) {
    return 'Business does not have any employees';
  }
  if (get(values, 'underPAYELimitYN') === 'true') {
    return 'All employees earn less than PAYE threshold';
  }
  return '';
};

export const cacheKeys = {
  lead: 'lead',
  opportunity: 'opportunity',
};

export const useCreateLead = () => {
  const firebase = useFirebase();
  const navigate = useNavigate();
  const location = useLocation();
  const { utmValues, otherValues } = getUtmFields(location);
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();

  return useMutation(
    (values) => {
      navigate(`/form`);
      return firebase.doCreateInitialLead(
        {
          OccupationFamily: stateStore.getIsPubOrWine()
            ? OCCUPATION_FAMILY_CODE_PUB
            : OCCUPATION_FAMILY_CODE_REST,
          ...values,
          ...utmValues,
          ...otherValues,
        },
        SCHEME_CODE,
        salesforceStore.salesforceLeadId || quoteStore.salesforceLeadId,
        salesforceStore.salesforceOpportunityId ||
          quoteStore.salesforceOpportunityId
      );
    },
    {
      onSuccess: async (values) => {
        quoteStore.setValues(values);
        if (values.salesforceOpportunityId) {
          await queryClient.fetchQuery(cacheKeys.opportunity, async () => {
            const result = await firebase.doGetQuoteInfoFromOpportunityID({
              opportunityID: values.salesforceOpportunityId,
              scheme: SCHEME_CODE,
            });
            salesforceStore.saveSalesforceData(result);
            quoteStore.setValues({
              salesforceLeadId: result.leadId,
              salesforceContactId: result.contactId,
              salesforceOpportunityId: result.opportunityId,
            });
          });
          navigate(
            `/opportunity/${values.salesforceOpportunityId}?${searchParams}`,
            { replace: true }
          );
        } else if (values.salesforceLeadId) {
          await queryClient.fetchQuery(cacheKeys.lead, async () => {
            const result = await firebase.doGetQuoteInfoFromLeadID({
              leadID: values.salesforceLeadId,
              scheme: SCHEME_CODE,
            });
            salesforceStore.saveSalesforceData(result);
            quoteStore.setValues({
              salesforceLeadId: result.leadId,
              salesforceContactId: result.contactId,
              salesforceOpportunityId: result.opportunityId,
            });
          });
          navigate(`/lead/${values.salesforceLeadId}?${searchParams}`, {
            replace: true,
          });
        }
      },

      onError: (err) => {
        console.error(err);
        Sentry.captureException(err);
        salesforceStore.setIsSalesforceWorking(false);
      },
    }
  );
};
export const useQuoteInfoFromLeadID = (leadID) => {
  const firebase = useFirebase();
  const navigate = useNavigate();
  return useQuery(
    [cacheKeys.lead],
    () => firebase.doGetQuoteInfoFromLeadID({ leadID, scheme: SCHEME_CODE }),
    {
      staleTime: Infinity,
      onError: (err) => {
        console.error(err);
        Sentry.captureException(err);
        salesforceStore.setIsSalesforceWorking(false);
        navigate(
          { pathname: '/form', state: { message: err.message } },
          { replace: true }
        );
      },
      onSuccess: (values) => {
        salesforceStore.saveSalesforceData(values);
      },
    }
  );
};
export const useQuoteInfoFromOpportunityID = (id) => {
  const firebase = useFirebase();
  const navigate = useNavigate();
  return useQuery(
    [cacheKeys.opportunity],
    () =>
      firebase.doGetQuoteInfoFromOpportunityID({
        opportunityID: id,
        scheme: SCHEME_CODE,
      }),
    {
      staleTime: Infinity,
      onError: (err) => {
        console.error(err);
        Sentry.captureException(err);
        salesforceStore.setIsSalesforceWorking(false);
        navigate(
          { pathname: '/form', state: { message: err.message } },
          { replace: true }
        );
      },
      onSuccess: (values) => {
        salesforceStore.saveSalesforceData(values);
      },
    }
  );
};

export const useSavePolicy = () => {
  const firebase = useFirebase();
  const navigate = useNavigate();
  const sessionId = get(window, 'AutopilotAnywhere.sessionId', '');
  return useMutation(
    (values) =>
      firebase.doRateSchemeServe({
        request: {
          ...values,
          InceptionDate: moment(values.EffectivePeriodStartDate).format(
            'YYYY-MM-DD'
          ),
          '2313ERNexempt': get(values, '2313ERN') ? 'No' : 'Yes',
          '2313FullRange': get(values, '2313FullRangeYN.value'),
          ExemptReason: getExemptReason(values),
          product: SCHEME_CODE,
          FirstName: values.FirstName || salesforceStore.firstName,
          LastName: values.LastName || salesforceStore.lastName,
          Email: values.Email || salesforceStore.email,
          Phone: values.Phone || salesforceStore.phone,
          salesforceOpportunityId: quoteStore.salesforceOpportunityId,
          salesforceLeadId: quoteStore.salesforceLeadId,
          salesforceContactId: quoteStore.salesforceContactId,
        },
        schemeId: stateStore.getIsPubOrWine() ? 2313 : 6247,
        sessionId,
      }),
    {
      onSuccess: async () => {
        salesforceStore.rateLoader = false;
        navigate(`/success`, { replace: true });
      },
      onError: (err) => {
        console.error(err);
        Sentry.captureException(err);
        navigate({ pathname: '/error', state: { message: err.message } });
      },
    }
  );
};
