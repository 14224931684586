import * as Yup from 'yup';
import moment from 'moment';

Yup.addMethod(Yup.string, 'isDate', function (errorMessage) {
  return this.test({
    name: 'idDate',
    message: errorMessage,
    test: (value) => moment(value, 'DD/MM/YYYY', true).isValid(),
  });
});
