import * as yup from 'yup';
import './customYupValidations';

export const validationScheme = {
  policyDisclaimer: yup.object().shape({}),
  businessType: yup.object().shape({
    '2313PuborWineBar': yup.object().shape({
      code: yup.string().required('Type of business to cover is required.'),
      value: yup.string().required('Type of business to cover is required.'),
    }),
  }),
  categoryCuisine: yup.object().shape({
    RestaurantType: yup.object().shape({
      code: yup.string().required('Restaurant Type is required.'),
      value: yup.string().required('Restaurant Type is required.'),
    }),
  }),
  annualTurnover: yup.object().shape({
    '2313Turnover': yup
      .number()
      .required('Estimated annual turnover is required.'),
  }),
  numberOfEmployees: yup.object().shape({
    '2313Employees': yup.number().required('Number of employees is required.'),
    '2313wageroll': yup.string().when('2313Employees', {
      is: (value) => value > 0,
      then: yup.string().required('Wage roll is required.'),
      otherwise: yup.string(),
    }),
  }),
  basicInfo: yup.object().shape({
    '2313Businessstatus': yup.object().shape({
      code: yup.string().required('Business status is required.'),
      value: yup.string().required('Business status is required.'),
    }),
    '2313tradingname': yup.string().required('Trading name is required.'),
    '2313riskaddress': yup.object().shape({
      addressline1: yup.string().required('Address Line 1 is required.'),
      town: yup.string().required('City is required field.'),
      postcode: yup.string().required('Postcode is required field.'),
    }),
    underPAYELimitYN: yup.string().when(['2313Employees', '2313riskaddress'], {
      is: (employees, address) =>
        employees > 0 &&
        address?.town &&
        !['Guernsey', 'Jersey'].includes(address?.town),
      then: yup.string().required('Answer about the PAYE limit is required.'),
      otherwise: yup.string(),
    }),
    '2313ERN': yup
      .string()
      .when(['2313Employees', '2313riskaddress', 'underPAYELimitYN'], {
        is: (employees, address, underPAYELimit) =>
          employees > 0 &&
          underPAYELimit === 'false' &&
          address?.town &&
          !['Guernsey', 'Jersey'].includes(address?.town),
        then: yup.string().required('ERN number is required.'),
        otherwise: yup.string(),
      }),
  }),
  experience: yup.object().shape({
    '2313yearstradingatthisaddress': yup.object().shape({
      code: yup.string().required('Number of years trading is required.'),
      value: yup.string().required('Number of years trading is required.'),
    }),
    PrevExperience: yup.string().when('2313yearstradingatthisaddress', {
      is: (value) => value.code === 'zeroYears',
      then: yup
        .string()
        .required('Details about previous experience are required.'),
      otherwise: yup.string(),
    }),
    '2313yrstradeexpDB': yup.object().shape({
      code: yup.string().required('Number of years trading is required.'),
      value: yup.string().required('Number of years trading is required.'),
    }),
    '2313SeasonalYN': yup.object().shape({
      code: yup
        .string()
        .required('Answer about year round trading is required.'),
      value: yup
        .string()
        .required('Answer about year round trading is required.'),
    }),
    TradeInfo: yup.string().when('2313SeasonalYN', {
      is: (value) => value.code === 'false',
      then: yup
        .string()
        .required(
          'Details about managing premises out of season are required.'
        ),
      otherwise: yup.string(),
    }),
    FCTraining: yup
      .object()
      .when(['2313PuborWineBar', '2313yearstradingatthisaddress'], {
        is: (pubWine, years) =>
          pubWine.code === 'fishAndChipShop' && years.code === 'zeroYears',
        then: yup.object().shape({
          code: yup
            .string()
            .required('Answer about menagerial experience is required.'),
          value: yup
            .string()
            .required('Answer about menagerial experience is required.'),
        }),
        otherwise: yup.object().shape({
          code: yup.string(),
          value: yup.string(),
        }),
      }),
  }),
  claims: yup.object().shape({
    '2313claimsYn': yup.object().shape({
      code: yup
        .string()
        .required(
          'Answer about suffering loss, claim and incident is required.'
        ),
      value: yup
        .string()
        .required(
          'Answer about suffering loss, claim and incident is required.'
        ),
    }),
  }),
  businessPremises: yup.object().shape({
    '2313GSofRYN': yup.object().shape({
      code: yup
        .string()
        .required('Answer about the state of repair is required.'),
      value: yup
        .string()
        .required('Answer about the state of repair is required.'),
    }),
    '2313SCYN': yup.object().shape({
      code: yup
        .string()
        .required('Answer about the separate entrance and exit is required.'),
      value: yup
        .string()
        .required('Answer about the separate entrance and exit is required.'),
    }),
    '2313notSCTXT': yup.string().when('2313SCYN', {
      is: (value) => value.code === 'false',
      then: yup
        .string()
        .required('Details about the entrance and exit are required.'),
      otherwise: yup.string(),
    }),
    '2313soleoccYN': yup.object().shape({
      code: yup.string().required('Answer about the occupation is required.'),
      value: yup.string().required('Answer about the occupation is required.'),
    }),
    '2313notoccsolelyTXT': yup.string().when('2313soleoccYN', {
      is: (value) => value.code === 'false',
      then: yup
        .string()
        .required('Details about other businesses are required.'),
      otherwise: yup.string(),
    }),
  }),
  constructionSpecification: yup.object().shape({
    '2313stndconYN': yup.object().shape({
      code: yup.string().required('Answer about the walls is required.'),
      value: yup.string().required('Answer about the walls is required.'),
    }),
    '2313stndconNTXT': yup.string().when('2313stndconYN', {
      is: (value) => value.code === 'false',
      then: yup
        .string()
        .required('Details about the wall construction are required.'),
      otherwise: yup.string(),
    }),
    '2313stndroofconYN': yup.object().shape({
      code: yup
        .string()
        .required('Answer about the roof construction is required.'),
      value: yup
        .string()
        .required('Answer about the roof construction is required.'),
    }),
    '2313roofstndconNTXT': yup.string().when('2313stndroofconYN', {
      is: (value) => value.code === 'false',
      then: yup
        .string()
        .required('Details about the roof construction are required.'),
      otherwise: yup.string(),
    }),
    '2313flatroofYN': yup.object().shape({
      code: yup
        .string()
        .required('Answer about the flat roof area is required.'),
      value: yup
        .string()
        .required('Answer about the flat roof area is required.'),
    }),
    '2313flatroofPCT': yup
      .string()
      .required('Approximate percentage is required.'),
    '2313flatroofconstruction': yup.object().when('2313flatroofYN', {
      is: (value) => value.code === 'other',
      then: yup.object().shape({
        code: yup.string().required('Flat roof construction type is required.'),
        value: yup
          .string()
          .required('Flat roof construction type is required.'),
      }),
      otherwise: yup.object().shape({
        code: yup.string(),
        value: yup.string(),
      }),
    }),
    '2313flatroofother': yup.string().when('2313flatroofconstruction', {
      is: (value) => value.code === 'other',
      then: yup
        .string()
        .required('Description of the flat roof construction is required.'),
      otherwise: yup.string(),
    }),
    '2313AgeBuildDB': yup.object().shape({
      code: yup
        .string()
        .required(
          'Answer about the time when the premises were built is required.'
        ),
      value: yup
        .string()
        .required(
          'Answer about the time when the premises were built is required.'
        ),
    }),
    Pre1700: yup.number().when('2313AgeBuildDB', {
      is: (value) => value.code === 'pre1850',
      then: yup
        .number()
        .required('Approximate year of construction is required.')
        .max(1850, 'Given year must not be after 1850.')
        .min(1000, 'Year must be more recent than 1000.'),
      otherwise: yup.number(),
    }),
  }),
  premisesDetails: yup.object().shape({
    '2313listedYN': yup.object().shape({
      code: yup
        .string()
        .required('Answer about the "Listed Building" status is required.'),
      value: yup
        .string()
        .required('Answer about the "Listed Building" status is required.'),
    }),
    '2313listedYDB': yup.object().when('2313listedYN', {
      is: (value) => value.code === 'true',
      then: yup.object().shape({
        code: yup.string().required('Listing grade is required.'),
        value: yup.string().required('Listing grade is required.'),
      }),
      otherwise: yup.object().shape({
        code: yup.string(),
        value: yup.string(),
      }),
    }),
    '2313locationYN': yup.object().shape({
      code: yup
        .string()
        .required('Answer about the location of the premises is required.'),
      value: yup
        .string()
        .required('Answer about the location of the premises is required.'),
    }),
    '2313infoshopcentTB': yup.string().when('2313locationYN', {
      is: (value) => value.code === 'true',
      then: yup
        .string()
        .required('Details about the premises location are required.'),
      otherwise: yup.string(),
    }),
    '2313owneroccYN': yup.object().shape({
      code: yup
        .string()
        .required('Answer about the overnight accomodation is required.'),
      value: yup
        .string()
        .required('Answer about the overnight accomodation is required.'),
    }),
    '2313ressccYN': yup.object().shape({
      code: yup
        .string()
        .required(
          'Answer about the private dwelling accomodation is required.'
        ),
      value: yup
        .string()
        .required(
          'Answer about the private dwelling accomodation is required.'
        ),
    }),
    AccommodationInfo: yup.string().when('2313ressccYN', {
      is: (value) => value.code === 'true',
      then: yup
        .string()
        .required(
          'Details about the private dwelling accomodation are required.'
        ),
      otherwise: yup.string(),
    }),
  }),
  exposureToDisasters: yup.object().shape({
    '2313floodYN': yup.object().shape({
      code: yup
        .string()
        .required('Answer about the flooding exposure is required.'),
      value: yup
        .string()
        .required('Answer about the flooding exposure is required.'),
    }),
    '2313FloodInfo': yup.string().when('2313floodYN', {
      is: (value) => value.code === 'false',
      then: yup
        .string()
        .required('Details about the flooding exposure are required.'),
      otherwise: yup.string(),
    }),
    '2313subsidenceYN': yup.object().shape({
      code: yup
        .string()
        .required('Answer about the the signs of subsidence is required.'),
      value: yup
        .string()
        .required('Answer about the the signs of subsidence is required.'),
    }),
    SubsInfo: yup.string().when('2313subsidenceYN', {
      is: (value) => value.code === 'false',
      then: yup
        .string()
        .required('Details about the the signs of subsidence are required.'),
      otherwise: yup.string(),
    }),
  }),
  installations: yup.object().shape({
    '2313IEECertYN': yup.object().shape({
      code: yup
        .string()
        .required('Answer about electrical installations is required.'),
      value: yup
        .string()
        .required('Answer about electrical installations is required.'),
    }),
    OpenFire: yup.object().shape({
      code: yup.string().required('Answer about open fire is required.'),
      value: yup.string().required('Answer about open fire is required.'),
    }),
  }),
  kitchenInfo: yup.object().shape({
    '2313CookingYN': yup.object().when('2313PuborWineBar', {
      is: (value) => value.code === 'wineBar' || value.code === 'publicHouse',
      then: yup.object().shape({
        code: yup
          .string()
          .required('Answer about cooking on the premises is required.'),
        value: yup
          .string()
          .required('Answer about cooking on the premises is required.'),
      }),
      otherwise: yup.object().shape({
        code: yup.string(),
        value: yup.string(),
      }),
    }),
    '2313FryingYN': yup.object().shape({
      code: yup
        .string()
        .required('Answer about frying on the premises is required.'),
      value: yup
        .string()
        .required('Answer about frying on the premises is required.'),
    }),
    '2313FullRangeYN': yup.object().when('2313FryingYN', {
      is: (value) => value.code === 'true',
      then: yup.object().shape({
        code: yup
          .string()
          .required('Answer about the full frying range is required.'),
        value: yup
          .string()
          .required('Answer about the full frying range is required.'),
      }),
      otherwise: yup.object().shape({
        code: yup.string(),
        value: yup.string(),
      }),
    }),
    '2313CleanMainYN': yup.object().when(['2313FullRangeYN', '2313FryingYN'], {
      is: (frying, range) => frying.code === 'true' && range.code === 'true',
      then: yup.object().shape({
        code: yup
          .string()
          .required(
            'Answer about maintaining and cleaning the kitchen required.'
          ),
        value: yup
          .string()
          .required(
            'Answer about maintaining and cleaning the kitchen required.'
          ),
      }),
      otherwise: yup.object().shape({
        code: yup.string(),
        value: yup.string(),
      }),
    }),
    '2313RangeMainYN': yup.object().when(['2313FullRangeYN', '2313FryingYN'], {
      is: (frying, range) => frying.code === 'true' && range.code === 'true',
      then: yup.object().shape({
        code: yup
          .string()
          .required(
            'Answer about maintaining and cleaning the range is required.'
          ),
        value: yup
          .string()
          .required(
            'Answer about maintaining and cleaning the range is required.'
          ),
      }),
      otherwise: yup.object().shape({
        code: yup.string(),
        value: yup.string(),
      }),
    }),
    '2313PresCookYN': yup.object().shape({
      code: yup.string().required('Answer about pressure cooking is required.'),
      value: yup
        .string()
        .required('Answer about pressure cooking is required.'),
    }),
  }),
  cateringInfo: yup.object().shape({
    '2313noofcoversN': yup
      .number()
      .required('Answer about the seating capacity is required.'),
    '2313SontheDDB': yup.object().shape({
      code: yup.string().required('Scores-On-The-Doors rating is required.'),
      value: yup.string().required('Scores-On-The-Doors rating is required.'),
    }),
    ClosingTime: yup.object().when('2313PuborWineBar', {
      is: (value) => value.code === 'takeaway',
      then: yup.object().shape({
        code: yup.string().required('Closing time is required.'),
        value: yup.string().required('Closing time is required.'),
      }),
      otherwise: yup.object().shape({
        code: yup.string(),
        value: yup.string(),
      }),
    }),
    '2313latelicense': yup.object().when('2313PuborWineBar', {
      is: (value) =>
        value.code !== 'takeaway' &&
        value.code !== 'fishAndChipShop' &&
        value.code !== 'sandwichShop' &&
        value.code !== 'cafe',
      then: yup.object().shape({
        code: yup
          .string()
          .required('Answer about working after 11 pm is required.'),
        value: yup
          .string()
          .required('Answer about working after 11 pm is required.'),
      }),
      otherwise: yup.object().shape({
        code: yup.string(),
        value: yup.string(),
      }),
    }),
    '2313latelicensetime': yup.string().when('2313latelicense', {
      is: (value) => value.code === 'true',
      then: yup
        .string()
        .required('Details about working after 11 pm required.'),
      otherwise: yup.string(),
    }),
  }),
  entertainmentInfo: yup.object().shape({
    2313: yup.object().shape({
      code: yup
        .string()
        .required('Answer about the entertainment is required.'),
      value: yup
        .string()
        .required('Answer about the entertainment is required.'),
    }),
    MusicOften: yup.object().when('2313', {
      is: (value) => value.code === 'true',
      then: yup.object().shape({
        code: yup
          .string()
          .required('Answer about the live music frequency is required.'),
        value: yup
          .string()
          .required('Answer about the live music frequency is required.'),
      }),
      otherwise: yup.object().shape({
        code: yup.string(),
        value: yup.string(),
      }),
    }),
    '2381musicdetails': yup.string().when('MusicOften', {
      is: (value) => value.code === 'true',
      then: yup
        .string()
        .required('Details about the offered entertainment are required.'),
      otherwise: yup.string(),
    }),
    '2313dancefloorYN': yup.object().shape({
      code: yup.string().required('Answer about the dance floor is required.'),
      value: yup.string().required('Answer about the dance floor is required.'),
    }),
    '3093Spillage': yup.object().when('2313dancefloorYN', {
      is: (value) => value.code === 'true',
      then: yup.object().shape({
        code: yup
          .string()
          .required('Answer about the "spillage policy" is required.'),
        value: yup
          .string()
          .required('Answer about the "spillage policy" is required.'),
      }),
      otherwise: yup.object().shape({
        code: yup.string(),
        value: yup.string(),
      }),
    }),
    '2313entfeeYn': yup.object().when('2313PuborWineBar', {
      is: (value) =>
        ['publicHouse', 'wineBar', 'gastroPub'].includes(value.code),
      then: yup.object().shape({
        code: yup
          .string()
          .required('Answer about the entrance fee is required.'),
        value: yup
          .string()
          .required('Answer about the entrance fee is required.'),
      }),
      otherwise: yup.object().shape({
        code: yup.string(),
        value: yup.string(),
      }),
    }),
    '2313entrancefeeinfo': yup.string().when('2313entfeeYn', {
      is: (value) => value.code === 'true',
      then: yup.string().required('Details about the entrance fee required.'),
      otherwise: yup.string(),
    }),
  }),
  staffGuestrooms: yup.object().shape({
    '2313doormenYN': yup.object().shape({
      code: yup.string().required('Answer about "Door Staff" is required.'),
      value: yup.string().required('Answer about "Door Staff" is required.'),
    }),
    '2313lincdoorYN': yup.object().when('2313doormenYN', {
      is: (value) => value.code === 'true',
      then: yup.object().shape({
        code: yup
          .string()
          .required('Answer about "Door Staff" approval is required.'),
        value: yup
          .string()
          .required('Answer about "Door Staff" approval is required.'),
      }),
      otherwise: yup.object().shape({
        code: yup.string(),
        value: yup.string(),
      }),
    }),
    DoorStaffInfo: yup.string().when('2313doormenYN', {
      is: (value) => value.code === 'true',
      then: yup.string().required('Details about the "Door Staff" required.'),
      otherwise: yup.string(),
    }),
    '2313roomsYn': yup.object().shape({
      code: yup
        .string()
        .required('Answer about available guestrooms is required.'),
      value: yup
        .string()
        .required('Answer about available guestrooms is required.'),
    }),
    '2313NoroomsDB': yup.number().when('2313roomsYn', {
      is: (value) => value.code === 'true',
      then: yup.number().required('The number of rooms is required.'),
      otherwise: yup.number(),
    }),
  }),
  delivery: yup.object().shape({
    '2313eventhireYN': yup.object().when('2313PuborWineBar', {
      is: (value) =>
        !['takeaway', 'fishAndChipShop', 'sandwichShop', 'cafe'].includes(
          value.code
        ),
      then: yup.object().shape({
        code: yup
          .string()
          .required('Answer about the private function frequency is required.'),
        value: yup
          .string()
          .required('Answer about the private function frequency is required.'),
      }),
      otherwise: yup.object().shape({
        code: yup.string(),
        value: yup.string(),
      }),
    }),
    EventHireInfo: yup.string().when('2313eventhireYN', {
      is: (value) => value.code === 'true',
      then: yup
        .string()
        .required('Details about the private functions are required.'),
      otherwise: yup.string(),
    }),
    '2313outcaterYN': yup.object().shape({
      code: yup.string().required('Answer about outside catering is required.'),
      value: yup
        .string()
        .required('Answer about outside catering is required.'),
    }),
    '2313OCdetails': yup.string().when('2313outcaterYN', {
      is: (value) => value.code === 'true',
      then: yup
        .string()
        .required('Details about outside catering are required.'),
      otherwise: yup.string(),
    }),
    '2313turnoverOC': yup.object().when('2313outcaterYN', {
      is: (value) => value.code === 'true',
      then: yup.object().shape({
        code: yup
          .string()
          .required('The annual turnover percentage is required.'),
        value: yup
          .string()
          .required('The annual turnover percentage is required.'),
      }),
      otherwise: yup.object().shape({
        code: yup.string(),
        value: yup.string(),
      }),
    }),
    Delivery: yup.object().when('2313PuborWineBar', {
      is: (value) =>
        ['takeaway', 'fishAndChipShop', 'sandwichShop', 'cafe'].includes(
          value.code
        ),
      then: yup.object().shape({
        code: yup
          .string()
          .required('Answer about the delivery services is required.'),
        value: yup
          .string()
          .required('Answer about the delivery services is required.'),
      }),
      otherwise: yup.object().shape({
        code: yup.string(),
        value: yup.string(),
      }),
    }),
    HowDelivery: yup.object().when('Delivery', {
      is: (value) => value.code === 'true',
      then: yup.object().shape({
        code: yup
          .string()
          .required('Answer about the delivery staff is required.'),
        value: yup
          .string()
          .required('Answer about the delivery staff is required.'),
      }),
      otherwise: yup.object().shape({
        code: yup.string(),
        value: yup.string(),
      }),
    }),
    CarDelivery: yup.number().when('HowDelivery', {
      is: (value) => value.code === 'ownEmployees',
      then: yup.number().required('The number of car deliveries is required.'),
      otherwise: yup.number(),
    }),
    BikeDelivery: yup.number().when('HowDelivery', {
      is: (value) => value.code === 'ownEmployees',
      then: yup.number().required('The number of bike deliveries is required.'),
      otherwise: yup.number(),
    }),
  }),
  miscellaneous: yup.object().shape({
    Shisha: yup.object().when('2313PuborWineBar', {
      is: (value) =>
        ['takeaway', 'fishAndChipShop', 'sandwichShop', 'cafe'].includes(
          value.code
        ),
      then: yup.object().shape({
        code: yup.string().required('Answer about the Shisha is required.'),
        value: yup.string().required('Answer about the Shisha is required.'),
      }),
      otherwise: yup.object().shape({
        code: yup.string(),
        value: yup.string(),
      }),
    }),
    '2313childrensplayareaYN': yup.object().when('2313PuborWineBar', {
      is: (value) =>
        !['takeaway', 'fishAndChipShop', 'sandwichShop', 'cafe'].includes(
          value.code
        ),
      then: yup.object().shape({
        code: yup.string().required('Answer about the play area is required.'),
        value: yup.string().required('Answer about the play area is required.'),
      }),
      otherwise: yup.object().shape({
        code: yup.string(),
        value: yup.string(),
      }),
    }),
    '2381CPADetails': yup.string().when('2313childrensplayareaYN', {
      is: (value) => value.code === 'true',
      then: yup.string().required('Details about the play area are required.'),
      otherwise: yup.string(),
    }),
    '2313ATMYN': yup.object().when('2313PuborWineBar', {
      is: (value) =>
        ['publicHouse', 'wineBar', 'gastroPub'].includes(value.code),
      then: yup.object().shape({
        code: yup
          .string()
          .required('Answer about an available ATM is required.'),
        value: yup
          .string()
          .required('Answer about an available ATM is required.'),
      }),
      otherwise: yup.object().shape({
        code: yup.string(),
        value: yup.string(),
      }),
    }),
    '2313ATMrespon': yup.object().when(['2313PuborWineBar', '2313ATMYN'], {
      is: (type, atm) =>
        ['publicHouse', 'wineBar', 'gastroPub'].includes(type.code) &&
        atm.code === 'true',
      then: yup.object().shape({
        code: yup
          .string()
          .required('Answer about the ATM responsibility is required.'),
        value: yup
          .string()
          .required('Answer about the ATM responsibility is required.'),
      }),
      otherwise: yup.object().shape({
        code: yup.string(),
        value: yup.string(),
      }),
    }),
    '2313ATMlimit': yup.number().when(['2313PuborWineBar', '2313ATMrespon'], {
      is: (type, atm) =>
        ['publicHouse', 'wineBar', 'gastroPub'].includes(type.code) &&
        atm.code === 'true',
      then: yup.number().required('Answer about the ATM maximum is required.'),
      otherwise: yup.number(),
    }),
    '2313ATMboltedownYN': yup
      .object()
      .when(['2313PuborWineBar', '2313ATMrespon'], {
        is: (type, atm) =>
          ['publicHouse', 'wineBar', 'gastroPub'].includes(type.code) &&
          atm.code === 'true',
        then: yup.object().shape({
          code: yup
            .string()
            .required('Answer about bolting down the ATM is required.'),
          value: yup
            .string()
            .required('Answer about bolting down the ATM is required.'),
        }),
        otherwise: yup.object().shape({
          code: yup.string(),
          value: yup.string(),
        }),
      }),
    '2313ATMemptiedYN': yup
      .object()
      .when(['2313PuborWineBar', '2313ATMrespon'], {
        is: (type, atm) =>
          ['publicHouse', 'wineBar', 'gastroPub'].includes(type.code) &&
          atm.code === 'true',
        then: yup.object().shape({
          code: yup
            .string()
            .required('Answer about emptying the ATM is required.'),
          value: yup
            .string()
            .required('Answer about emptying the ATM is required.'),
        }),
        otherwise: yup.object().shape({
          code: yup.string(),
          value: yup.string(),
        }),
      }),
    '2313ATMCCTVYN': yup.object().when(['2313PuborWineBar', '2313ATMrespon'], {
      is: (type, atm) =>
        ['publicHouse', 'wineBar', 'gastroPub'].includes(type.code) &&
        atm.code === 'true',
      then: yup.object().shape({
        code: yup
          .string()
          .required(
            'Answer about video surveillance covering the ATM is required.'
          ),
        value: yup
          .string()
          .required(
            'Answer about video surveillance covering the ATM is required.'
          ),
      }),
      otherwise: yup.object().shape({
        code: yup.string(),
        value: yup.string(),
      }),
    }),
  }),
  securityRequirements: yup.object().shape({
    '2313minsecYN': yup.object().shape({
      code: yup
        .string()
        .required(
          'Answer about meeting minimum security requirements is required.'
        ),
      value: yup
        .string()
        .required(
          'Answer about meeting minimum security requirements is required.'
        ),
    }),
    MinSecInfo: yup.string().when('2313minsecYN', {
      is: (value) => value.code === 'false',
      then: yup
        .string()
        .required(
          'Details about not meeting the security requirments are required.'
        ),
      otherwise: yup.string(),
    }),
  }),
  premisesProtection: yup.object().shape({
    '2313alarmYN': yup.object().shape({
      code: yup.string().required('Answer about alarm protection is required.'),
      value: yup
        .string()
        .required('Answer about alarm protection is required.'),
    }),
    '2313typealarmDB': yup.object().when('2313alarmYN', {
      is: (value) => value.code === 'true',
      then: yup.object().shape({
        code: yup.string().required('The type of alarm is required.'),
        value: yup.string().required('The type of alarm is required.'),
      }),
      otherwise: yup.object().shape({
        code: yup.string(),
        value: yup.string(),
      }),
    }),
    PoliceResponse: yup.object().when('2313typealarmDB', {
      is: (value) =>
        ['digitalCommunicator', 'singlePath', 'dualPath'].includes(value.code),
      then: yup.object().shape({
        code: yup
          .string()
          .required('Answer about police response is required.'),
        value: yup
          .string()
          .required('Answer about police response is required.'),
      }),
      otherwise: yup.object().shape({
        code: yup.string(),
        value: yup.string(),
      }),
    }),
    PoliceResponseLevel: yup.object().when('PoliceResponse', {
      is: (value) => value.code === 'true',
      then: yup.object().shape({
        code: yup
          .string()
          .required('The level of police response is required.'),
        value: yup
          .string()
          .required('The level of police response is required.'),
      }),
      otherwise: yup.object().shape({
        code: yup.string(),
        value: yup.string(),
      }),
    }),
    '2313grillesYN': yup.object().shape({
      code: yup
        .string()
        .required('Answer about window protection is required.'),
      value: yup
        .string()
        .required('Answer about window protection is required.'),
    }),
    '2313shuttYN': yup.object().shape({
      code: yup.string().required('Answer about door protection is required.'),
      value: yup.string().required('Answer about door protection is required.'),
    }),
  }),
  fireDetection: yup.object().shape({
    FireAlarmYN: yup.object().when('2313roomsYn', {
      is: (value) => value.code === 'true',
      then: yup.object().shape({
        code: yup
          .string()
          .required('Answer about the fire detection system is required.'),
        value: yup
          .string()
          .required('Answer about the fire detection system is required.'),
      }),
      otherwise: yup.object().shape({
        code: yup.string(),
        value: yup.string(),
      }),
    }),
    FireAssess: yup.object().when(['2313roomsYn', 'FireAlarmYN'], {
      is: (rooms, alarm) => rooms.code === 'true' && alarm.code === 'false',
      then: yup.object().shape({
        code: yup
          .string()
          .required('Answer about the Fire Risk Assessment is required.'),
        value: yup
          .string()
          .required('Answer about the Fire Risk Assessment is required.'),
      }),
      otherwise: yup.object().shape({
        code: yup.string(),
        value: yup.string(),
      }),
    }),
    FireMeasures: yup.string().when(['2313roomsYn', 'FireAlarmYN'], {
      is: (rooms, alarm) => rooms.code === 'true' && alarm.code === 'false',
      then: yup
        .string()
        .required('Details about the fire safety measures are required.'),
      otherwise: yup.string(),
    }),
    Ansul: yup.object().when('2313FullRangeYN', {
      is: (value) => value.code === 'true',
      then: yup.object().shape({
        code: yup
          .string()
          .required('Answer about the Ansul Fire Suppression is required.'),
        value: yup
          .string()
          .required('Answer about the Ansul Fire Suppression is required.'),
      }),
      otherwise: yup.object().shape({
        code: yup.string(),
        value: yup.string(),
      }),
    }),
    FireAlarm: yup.object().when('2313FullRangeYN', {
      is: (value) => value.code === 'true',
      then: yup.object().shape({
        code: yup.string().required('Answer about the fire alarm is required.'),
        value: yup
          .string()
          .required('Answer about the fire alarm is required.'),
      }),
      otherwise: yup.object().shape({
        code: yup.string(),
        value: yup.string(),
      }),
    }),
    FireAlarmInfo: yup.object().when(['2313FullRangeYN', 'FireAlarm'], {
      is: (rooms, alarm) => rooms.code === 'true' && alarm.code === 'true',
      then: yup.object().shape({
        code: yup.string().required('The type of fire alarm is required.'),
        value: yup.string().required('The type of fire alarm is required.'),
      }),
      otherwise: yup.object().shape({
        code: yup.string(),
        value: yup.string(),
      }),
    }),
  }),
  videoSurveillance: yup.object().shape({
    '2313cctvDB': yup.object().shape({
      code: yup
        .string()
        .required(
          'Answer about the CCTV/Video Surveillance system is required.'
        ),
      value: yup
        .string()
        .required(
          'Answer about the CCTV/Video Surveillance system is required.'
        ),
    }),
    CCTVRecord: yup.object().when('2313cctvDB', {
      is: (value) => value.code === 'true',
      then: yup.object().shape({
        code: yup
          .string()
          .required(
            'Answer about recording the surveillance system is required.'
          ),
        value: yup
          .string()
          .required(
            'Answer about recording the surveillance system is required.'
          ),
      }),
      otherwise: yup.object().shape({
        code: yup.string(),
        value: yup.string(),
      }),
    }),
    CCTVDays: yup.number().when('CCTVRecord', {
      is: (value) => value.code === 'true',
      then: yup
        .number()
        .required(
          'Number of days allowed for storing the recording is required.'
        ),
      otherwise: yup.number(),
    }),
    CCTVOffsite: yup.object().when('CCTVRecord', {
      is: (value) => value.code === 'true',
      then: yup.object().shape({
        code: yup
          .string()
          .required(
            'Answer about where the recordings are scored is required.'
          ),
        value: yup
          .string()
          .required(
            'Answer about where the recordings are scored is required.'
          ),
      }),
      otherwise: yup.object().shape({
        code: yup.string(),
        value: yup.string(),
      }),
    }),
  }),
  healthSafetyPolicy: yup.object().shape({
    '2313HSassess': yup.object().shape({
      code: yup
        .string()
        .required('Answer about the Health and Safety Policy is required.'),
      value: yup
        .string()
        .required('Answer about the Health and Safety Policy is required.'),
    }),
    '2313HSPolicy': yup.object().shape({
      code: yup
        .string()
        .required(
          'Answer about the Health and Safety Risk Assessment is required.'
        ),
      value: yup
        .string()
        .required(
          'Answer about the Health and Safety Risk Assessment is required.'
        ),
    }),
  }),
  sumsInsured: yup.object().shape({
    '2313BuildSIM': yup.number().required('Cover for buildings is required.'),
    LossofRent: yup.number().when('2313BuildSIM', {
      is: (value) => value > 0,
      then: yup.number().required('Loss of rent is required.'),
      otherwise: yup.number(),
    }),
    PlayingSurfaces: yup
      .number()
      .max(15000, 'Playing surfaces value must be under £15,000.')
      .required('Playing surfaces information is required'),
    '2313TIM': yup
      .number()
      .required('Cover for tenants improvements are required.'),
    '2313TradeContM': yup
      .number()
      .required('Cover for trade fixtures and other contents is required.'),
    '2313EleEquipM': yup
      .number()
      .required('Cover for electronic equipment is required.'),
    '2313Stock': yup.number().required('Cover for stock is required.'),
    '2313WSM': yup
      .number()
      .required('Cover for wines and spirits is required.'),
    LossOfLicenceYesNo: yup.boolean().when('2313WSM', {
      is: (value) => value > 0,
      then: yup
        .boolean()
        .required(
          'Answer about the loss of alcohol license cover is required.'
        ),
      otherwise: yup.boolean,
    }),
    '2313TobaccoM': yup
      .number()
      .required('Cover for tobacco products is required.'),
    '2313FFM': yup
      .number()
      .required('Cover for deterioration of stock is required.'),
    '2313BusIntLimit': yup
      .number()
      .min(500000, 'Cover for business interruption must be over £500000.')
      .required('Cover for business interruption required.'),
    '4072IndemnityPeriod': yup.object().shape({
      code: yup.string().required('Indemnity period is required.'),
      value: yup.string().required('Indemnity period is required.'),
    }),
    '2313GoodsInTransit': yup
      .number()
      .required('Cover for goods in transit required.'),
    '2313MoneyTransitM': yup
      .number()
      .required('Cover for money during business hours is required.'),
    '2313MoneySafeM': yup
      .number()
      .required('Cover for money outside business hours is required.'),
    PersonalContents: yup.boolean().when('2313owneroccYN', {
      is: (value) => value.code === 'true',
      then: yup
        .boolean()
        .required('Answer about personal / domestic contents is required.'),
      otherwise: yup.boolean(),
    }),
    '2313DomContM': yup.number().when('PersonalContents', {
      is: (value) => value === 'true',
      then: yup
        .number()
        .required('Cover for personal / domestic contents is required.'),
      otherwise: yup.number(),
    }),
  }),
  pointOfContact: yup.object().shape({
    FirstName: yup.string().required('First name is required.'),
    LastName: yup.string().required('Last name is required.'),
    Email: yup
      .string()
      .email('Must be a valid email.')
      .required('Email address is required.'),
    Phone: yup.string().required('Phone number is required.'),
  }),
  coverStart: yup.object().shape({
    EffectivePeriodStartDate: yup
      .string()
      .required('Cover start date is required field.'),
  }),
  acceptanceCriteria: yup.object().shape({
    '2313disclosheader6': yup
      .string()
      .required('Answer about the cancelled insurance contranct is required.'),
    '2313declinedetails': yup.string().when('2313disclosheader6', {
      is: (value) => value === 'Yes',
      then: yup
        .string()
        .required(
          'Details about the cancelled insurance contranct are required.'
        ),
      otherwise: yup.string(),
    }),
    Void: yup
      .string()
      .required('Answer about the void insurance contract is required.'),
    VoidInfo: yup.string().when('Void', {
      is: (value) => value === 'Yes',
      then: yup
        .string()
        .required('Details about the void insurance contract are required.'),
      otherwise: yup.string(),
    }),
    SpecialTerms: yup
      .string()
      .required('Answer about the special terms is required.'),
    SpecialTermsInfo: yup.string().when('SpecialTerms', {
      is: (value) => value === 'Yes',
      then: yup
        .string()
        .required('Details about the special terms are required.'),
      otherwise: yup.string(),
    }),
    NonCompliance: yup
      .string()
      .required(
        'Answer about restriction or cancellation due to non-compliance is required.'
      ),
    NonComplianceInfo: yup.string().when('NonCompliance', {
      is: (value) => value === 'Yes',
      then: yup
        .string()
        .required(
          'Details about restriction or cancellation due to non-compliance are required.'
        ),
      otherwise: yup.string(),
    }),
    CriminalRecord: yup
      .string()
      .required('Answer about criminal record is required.'),
    CriminalRecordInfo: yup.string().when('CriminalRecord', {
      is: (value) => value === 'Yes',
      then: yup
        .string()
        .required('Details about criminal record are required.'),
      otherwise: yup.string(),
    }),
    DecHSGen: yup
      .string()
      .required('Answer about the health and safety breach is required.'),
    DecHSGenInfo: yup.string().when('DecHSGen', {
      is: (value) => value === 'Yes',
      then: yup
        .string()
        .required('Details about the health and safety breach are required.'),
      otherwise: yup.string(),
    }),
    '2313disclosureheader3': yup
      .string()
      .required(
        'Answer about being served with a prohibition notice is required.'
      ),
    '2313convictiondetails': yup.string().when('2313disclosureheader3', {
      is: (value) => value === 'Yes',
      then: yup
        .string()
        .required(
          'Details about being served with a prohibition notice are required.'
        ),
      otherwise: yup.string(),
    }),
    DecHSCleanup: yup
      .string()
      .required(
        'Answer about being served with a clean-up notice is required.'
      ),
    DecHSCleanupInfo: yup.string().when('DecHSCleanup', {
      is: (value) => value === 'Yes',
      then: yup
        .string()
        .required(
          'Details about being served with a clean-up notice are required.'
        ),
      otherwise: yup.string(),
    }),
    HSVessels: yup
      .string()
      .required(
        'Answer about inspecting lifting plant and pressure vessels is required.'
      ),
    HSObligations: yup
      .string()
      .required(
        'Answer about complying with their legal obligations is required.'
      ),
    '2313disclosheader4': yup
      .string()
      .required('Answer about being declared bankrupt is required.'),
    '2313CCJdetails': yup.string().when('2313disclosheader4', {
      is: (value) => value === 'Yes',
      then: yup
        .string()
        .required('Details about being declared bankrupt are required.'),
      otherwise: yup.string(),
    }),
    Disqualified: yup
      .string()
      .required(
        'Answer about being disqualified from holding a directorship is required.'
      ),
    DisqualifiedInfo: yup.string().when('Disqualified', {
      is: (value) => value === 'Yes',
      then: yup
        .string()
        .required(
          'Details about being disqualified from holding a directorship are required.'
        ),
      otherwise: yup.string(),
    }),
    CCJPersonal: yup
      .string()
      .required(
        'Answer about being subject of a Courtney Court Judgement as private individuals is required.'
      ),
    CCJPersonalInfo: yup.string().when('CCJPersonal', {
      is: (value) => value === 'Yes',
      then: yup
        .string()
        .required(
          'Details about being subject of a Courtney Court Judgement as private individuals are required.'
        ),
      otherwise: yup.string(),
    }),
    CCJBusiness: yup
      .string()
      .required(
        'Answer about being subject of a Courtney Court Judgement as a director or partner is required.'
      ),
    CCJBusinessInfo: yup.string().when('CCJBusiness', {
      is: (value) => value === 'Yes',
      then: yup
        .string()
        .required(
          'Details about being subject of a Courtney Court Judgement as a director or partner are required.'
        ),
      otherwise: yup.string(),
    }),
    '2313disclosheader5': yup
      .string()
      .required(
        'Answer about being officers of an insolvent company is required.'
      ),
    '2313admindetails': yup.string().when('2313disclosheader5', {
      is: (value) => value === 'Yes',
      then: yup
        .string()
        .required(
          'Details about being officers of an insolvent company are required.'
        ),
      otherwise: yup.string(),
    }),
  }),
};
