import { useEffect, useState } from 'react';

export function useOnScreen(ref) {
  const [intersectionHeight, setIntersectionHeight] = useState(0);
  const observer = new IntersectionObserver(
    ([entry]) => setIntersectionHeight(entry.intersectionRect.height),
    { threshold: [0, 0.3, 0.75, 0.8, 0.85, 0.9, 1] }
  );

  useEffect(() => {
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [ref, observer]);

  return Math.round(intersectionHeight);
}
