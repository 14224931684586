import React, { useState } from 'react';
import { css, cx } from 'emotion';
import questionMark from '../../assets/questionmark.svg';
import { Modal } from './Modal';

const infoIconStyle = css`
  width: 1.25rem;
  height: 1.25rem;
`;
const tooltipHolder = css`
  display: inline;
`;
const removeDefaultButtonStyle = css`
  background: unset;
  width: 100%;
  text-align: left;
  border: none;
`;
const largeModalTitleMarginStyle = css`
  > div {
    margin-bottom: 1.5rem;
  }
`;

export const HelpModal = (props) => {
  const { customStyle, helpTextChildren, helpTitle } = props;
  const [showModal, setShowModal] = useState(false);
  return (
    <div className={cx(tooltipHolder, customStyle)}>
      <button
        type="button"
        onClick={() => setShowModal(true)}
        className={removeDefaultButtonStyle}
      >
        <img src={questionMark} className={infoIconStyle} alt="info" />
      </button>
      {showModal && (
        <Modal
          handleClose={() => setShowModal(false)}
          customModalStyle={largeModalTitleMarginStyle}
          show={showModal}
          title={helpTitle}
        >
          {helpTextChildren}
        </Modal>
      )}
    </div>
  );
};
